<template>
	<div ref="target" class="filter">
		<FilterHead
			:ref="field.variable"
			:filter-head="field.placeholder"
			:filter-icon="field.icon"
			:is-open="field.isOpen"
			:is-active="filterState & !field.isOpen"
			:class="{ 'a-filter--active': filterState & !field.isOpen }"
			@openFilter="toggleFilter"
		/>
		<div v-if="field.isOpen" class="filter-options__container">
			<div class="filter-options__head">
				<label>Filter</label>
				<button class="a-button a-anchor a-anchor--login" @click="clearFilter()">Reset</button>
			</div>
			<div v-if="field.searchable" class="search">
				<input v-model="originString" type="text" class="a-input-filter" />
				<i class="fas fa-search" />
			</div>

			<div class="a-input__checkbox-list">
				<Loader
					v-if="field.variable === 'categories'"
					:is-loading="loadingStateCategories"
					:color="'spinner--red'"
				/>
				<Loader
					v-if="field.variable === 'paymentMethods'"
					:is-loading="loadingStatePaymentMethods"
					:color="'spinner--red'"
				/>
				<Loader
					v-if="field.variable === 'locations'"
					:is-loading="loadingStateLocations"
					:color="'spinner--red'"
				/>
				<Loader
					v-if="field.variable === 'openingTimes'"
					:is-loading="loadingStateOpeningTimes"
					:color="'spinner--red'"
				/>
				<label v-for="item in fields" :key="item.name" class="a-input__checkbox-container">
					{{ item.name }}
					<input
						v-if="field.variable === 'categories'"
						:checked="filters.categories.includes(item.category_id)"
						:name="item.name"
						type="checkbox"
						@input="setMachineFilters(item)"
					/>
					<input
						v-if="field.variable === 'paymentMethods'"
						:checked="filters.paymentMethods.includes(item.payment_method_id)"
						:name="item.name"
						type="checkbox"
						@input="setMachineFilters(item)"
					/>
					<input
						v-if="field.variable === 'locations'"
						:checked="filters.locations.includes(item.location_id)"
						:name="item.name"
						type="checkbox"
						@input="setMachineFilters(item)"
					/>
					<input
						v-if="field.variable === 'openingTimes'"
						:checked="filters.openingTimes.includes(item.opening_time_id)"
						:name="item.name"
						type="checkbox"
						@input="setMachineFilters(item)"
					/>
					<span class="a-input__checkbox-checkmark" />
				</label>
			</div>
			<div class="c-divider" />
			<button class="c-button c-button--primary" @click="setFilter()">
				<span>Toepassen</span>
			</button>
		</div>
	</div>
</template>

<script src="./FilterPicker.controller.js"></script>
<style scoped lang="scss" src="./FilterPicker.styles.scss"></style>
