<template>
	<div class="a-filter-head" :class="{ 'a-filter-head--active': isOpen }" @click="changeOpenState()">
		<img
			class="a-filter-head__icona"
			:src="
				!isActive
					? require(`@/assets/images/icons/${filterIcon}.svg`)
					: require(`@/assets/images/icons/${filterIcon}--red.svg`)
			"
		/>
		<p class="a-filter-head__text">
			{{ filterHead }}
		</p>
		<img
			:src="
				!isActive
					? require('@/assets/images/icons/chevron-down.svg')
					: require('@/assets/images/icons/chevron-down--red.svg')
			"
			class="a-filter-head__arrow"
			:class="{ 'a-filter-head__arrow--active': isOpen }"
		/>
	</div>
</template>
<script src="./FilterHead.controller.js"></script>
<style lang="scss" src="./FilterHead.styles.scss"></style>
