<template>
  <div class="c-tabs">
    <ul class="c-tabs__header">
      <li
        v-if="machine.is_premium"
        class="c-tabs__header--item"
      >
        <a
          :class="{ active: isActive('details') }"
          href="#details"
          @click.prevent="setActive('details')"
        >Details</a>
      </li>
      <li 
        v-if="machine.is_premium"
        class="c-tabs__header--item"
      >
        <a
          :class="{ active: isActive('images') }"
          href="#images"
          @click.prevent="setActive('images')"
        >Foto's</a>
      </li>
      <li class="c-tabs__header--item">
        <a
          :class="{ active: machine.is_premium ? isActive('payment') : true }"
          href="#payment"
          @click.prevent="setActive('payment')"
        >Betaalmogelijkheden</a>
      </li>
      <li
        v-if="machine.is_premium"
        class="c-tabs__header--item"
      >
        <a
          :class="{ active: isActive('opening') }"
          href="#opening"
          @click.prevent="setActive('opening')"
        >Openingsuren</a>
      </li>
    </ul>
    <div
      class="c-tabs__content"
    >
      <TabDetails
        class="c-tabs__content--item"
        :class="{ active: isActive('details') }"
      />
      <TabImages
        ref="tabImages"
        class="c-tabs__content--item"
        :class="{ active: isActive('images') }"
      /><TabPayment
        class="c-tabs__content--item"
        :class="{ active: machine.is_premium ? isActive('payment') : true }"
      /><TabOpening
        class="c-tabs__content--item"
        :class="{ active: isActive('opening') }"
      />
    </div>
  </div>
</template>

<script src="./Tabs.controller.js"></script>
<style lang="scss" src="./Tabs.styles.scss"></style>