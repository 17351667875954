<template>
  <header
    class="o-header"
    :class="defaultHeader ? '' : 'o-header--dark'"
  >
    <div class="o-header__bar">
      <a
        v-if="defaultHeader"
        class="o-header__bar__logo"
        title="Ga naar home"
        href="/"
      >
        <img
          class="a-logo a-logo-desktop u-xl-hide"
          src="~@/assets/images/logo.svg"
          alt="vending mapp"
        >
        <img
          class="a-logo a-logo-mobile u-xl-show"
          src="~@/assets/images/logo.svg"
          alt="vending mapp"
        >
      </a>

      <div class="o-header__nav">
        <nav
          class="m-navigation"
          role="navigation"
        >
          <ul class="m-navigation__nav u-xl-hide">
            <li
              v-for="link in headerLinks"
              :key="link"
              class="m-navigation__item"
            >
              <router-link
                :class="{ active: isActiveLink(link.pathName) }"
                :to="{ name: link.pathName }"
                aria-expanded="false"
              >
                {{ link.title }}
              </router-link>
            </li>
            <!-- TODO: Unhide when integrating multi language -->
            <li
              v-if="false"
              class="m-navigation__item"
            >
              <Language />
            </li>
            <li class="m-navigation__item">
              <router-link
                v-if="defaultHeader"
                :to="{ name: 'Machine-create' }"
                aria-expanded="false"
              >
                <button class="c-button">
                  <img
                    class="c-button__icon"
                    src="~@/assets/images/icons/plus.svg"
                    alt="vending mapp"
                  >
                  <span>Voeg automaat toe</span>
                </button>
              </router-link>
            </li>
          </ul>
          <div class="m-navigation__actions u-xl-show">
            <Hamburger
              :show-flyout="showFlyout"
              @hamburgerClicked="toggleFlyout"
            />
          </div>
        </nav>
      </div>
    </div>
    <Flyout
      :show-flyout="showFlyout"
      :toggle-flyout="toggleFlyout"
      @hamburgerClicked="toggleFlyout"
    />
  </header>
</template>

<script src="./Header.controller.js"></script>
<style lang="scss" src="./Header.styles.scss"></style>
