const ACCOUNT_MUTATION_TYPES = Object.freeze({
	LOGIN: "LOGIN",
	LOGOUT: "LOGOUT",
	REGISTER: "REGISTER",
	RESET_PASSWORD: "RESET_PASSWORD",
	FORGOT_PASSWORD: "FORGOT_PASSWORD",

	SET_TOKEN: "SET_TOKEN",
	REMOVE_TOKEN: "REMOVE_TOKEN",
	// SET_CURRENT_USER: 'SET_CURRENT_USER',

	SET_ERROR: "SET_ERROR",
	SET_LOADING_STATE: "SET_LOADING_STATE",
	// SET_RESET_PASSORD_LOADING_STATE: 'SET_RESET_PASSORD_LOADING_STATE',
	// SET_FORGOT_PASSORD_LOADING_STATE: 'SET_FORGOT_PASSORD_LOADING_STATE',
	// SET_SUCCESSFUL_REGISTER: 'SET_SUCCESSFUL_REGISTER',
	// SET_USER: 'SET_CURRENT_USER',

	// FETCH_DATA_EDIT_USER: 'FETCH_DATA_EDIT_USER',
});

export default ACCOUNT_MUTATION_TYPES;
