import _ from 'lodash';

export default () => ({
  machines: [],
  machine: {},
  categories: [],
  faq:[],
  status: {
    loading: false,
    loadingCategories: false,
    loadingPaymentMethods: false,
    loadingLocations: false,
    loadingOpeningTimes: false,
    loadingMachineForm: false,
    error: '',
  },
  filters:{
    categories:[],
    paymentMethods: [],
    locations: [],
    openingTimes: [],
    location: {
      latitude: null,
      longitude: null,
      formattedAddress: ""
    },
    shelter: ""
  },
  firstLoad: false,
});
