export default {
  name: 'CtaGrid',
  props:{
    images: Array,
    title: String,
    description: String,
    buttonText: String,
    rowReverse: Boolean
  },
  data: () => ({
    
  })
};