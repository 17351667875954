<template>
  <div class="c-tab__opening">
    <!-- <p >


    </p> -->

    <!-- <div class="grid">
      <div
        v-for="hour in hours"
        :key="hour.day"
        class="row"
      >
        <div class="col-md-2 col-4">
          <strong>{{ hour.day }}</strong>
        </div>
        <div class="col-md-10 col-8">
          <div class="c-tab__opening-hours">
            <img
              src="@/assets/images/icons/clock.svg"
              alt="opening hours"
            >
            <p>{{ hour.hours }}</p>
          </div>
        </div>
      </div>
    </div> -->

    <div class="c-opening">
      <div class="c-opening__days">
        <strong
          v-for="hour in hours"
          :key="hour.day"
        >{{ hour.day }}</strong>
      </div>
      <div class="c-opening__hours">
        <div
          v-for="opening in machine.opening_hours"
          :key="opening.day_of_week"
        >
          <img
            src="@/assets/images/icons/clock.svg"
            alt="opening hours"
          >
          <p>{{ opening.start_hour }} / {{ opening.close_hour }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./TabOpening.controller.js"></script>
<style lang="scss" src="./TabOpening.styles.scss"></style>
