const MACHINE_MUTATION_TYPES = Object.freeze({
  FETCH_DATA_MACHINES: 'FETCH_DATA_MACHINES',
  FETCH_DATA_MACHINE: 'FETCH_DATA_MACHINE',
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  FETCH_FAQ: 'FETCH_FAQ',
  FETCH_PAYMENT_METHODS: 'FETCH_PAYMENT_METHODS',
  FETCH_LOCATIONS: 'FETCH_LOCATIONS',
  FETCH_OPENING_TIMES: 'FETCH_OPENING_TIMES',
  SET_OUT_OF_USE: 'SET_OUT_OF_USE',
  SET_FAQ: 'SET_FAQ',

  CREATE_MACHINE: 'CREATE_MACHINE',
  UPDATE_MACHINE: 'UPDATE_MACHINE',

  APPEND_MACHINES: 'APPEND_MACHINES',
  CLEAR_MACHINE: 'CLEAR_MACHINE',
  CLEAR_MACHINES: 'CLEAR_MACHINES',
  SET_MACHINE: 'SET_MACHINE',
  SET_SEARCH_LOCATION: 'SET_SEARCH_LOCATION',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_OPENING_TIMES: 'SET_OPENING_TIMES',
  SET_MACHINE_FILTERS: 'SET_MACHINE_FILTERS',
  CLEAR_MACHINE_FILTER: 'CLEAR_MACHINE_FILTER',
  CLEAR_MACHINE_FILTERS: 'CLEAR_MACHINE_FILTERS',
  TOGGLE_FIRST_LOAD: 'TOGGLE_FIRST_LOAD',

  SET_ERROR: 'SET_ERROR',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_LOADING_STATE_CATEGORIES: 'SET_LOADING_STATE_CATEGORIES',
  SET_LOADING_STATE_LOCATIONS: 'SET_LOADING_STATE_LOCATIONS',
  SET_LOADING_STATE_OPENING_TIMES: 'SET_LOADING_STATE_OPENING_TIMES',
  SET_LOADING_STATE_PAYMENT_METHODS: 'SET_LOADING_STATE_PAYMENT_METHODS',
  SET_LOADING_STATE_MACHINE_FORM: 'SET_LOADING_STATE_MACHINE_FORM',
});

export default MACHINE_MUTATION_TYPES;
