<template>
  <div
    class="c-hamburger"
    @click="hamburgerClicked()"
  >
    <img
      v-if="!showFlyout"
      src="~@/assets/images/icons/hamburger.svg"
      alt="mobile menu"
    >
    <img
      v-else
      src="~@/assets/images/icons/close-white.svg"
      alt="close mobile menu"
    >
  </div>
</template>

<script src="./Hamburger.controller.js">
</script>

<style lang="scss" src="./Hamburger.styles.scss">
</style>
