import Multiselect from "@vueform/multiselect";

import { GoogleSearchLocation } from "@/components";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "Searchbar",
  components: {
    Multiselect,
    GoogleSearchLocation
  },
  data: () => ({
    location: null,
    selectedCategory: null,
    currentPositionisActive: false
  }),
  computed:{
    ...mapGetters("machine", {
      categories: "getCategories",
      filters: "getFilters"
    }),
  },
  methods: {
    ...mapMutations("machine", {
      setMachineFilter: "SET_MACHINE_FILTERS",
    }),
    updateSelectedCategory(e){
      this.setMachineFilter(...this.categories.filter(c => c.category_id === e))
    },
    getCurrentLocation() {
      this.$refs.googleSearchLocation.getCurrentLocation();
    },
  },
  created(){
  }
};
