import { mapMutations, mapGetters } from "vuex";
import _ from "lodash";

export const updateRouteQueryByState = {
	computed: {
		...mapGetters("machine", {
			filters: "getFilters",
			categories: "getCategories",
			paymentMethods: "getPaymentMethods",
			locations: "getLocations",
			openingTimes: "getOpeningTimes",
		}),
	},
	methods: {
		...mapMutations("machine", {
			clearMachineFilters: "CLEAR_MACHINE_FILTERS",
			setSearchLocation: "SET_SEARCH_LOCATION",
			setOpeningTimes: "SET_OPENING_TIMES",
		}),
		updateRouteQueryByState() {
			const queryObject = {};

			// Fill Filter Categories
			if (this.filters.categories.length > 0) {
				let categorieString = "";
				this.filters.categories.forEach((id) => {
					categorieString = categorieString.concat(
						this.categories.find((c) => c.category_id === id).name.toLowerCase() + ","
					);
				});
				queryObject.categories = categorieString.slice(0, -1);
			}

			// Fill Filter Payment Methods
			if (this.filters.paymentMethods.length > 0) {
				let paymentMethodsString = "";
				this.filters.paymentMethods.forEach((id) => {
					paymentMethodsString = paymentMethodsString.concat(
						this.paymentMethods.find((c) => c.payment_method_id === id).name.toLowerCase() + ","
					);
				});
				queryObject.paymentmethods = paymentMethodsString.slice(0, -1);
			}

			// Fill Filter Locations
			if (this.filters.locations.length > 0) {
				let locationsString = "";
				this.filters.locations.forEach((id) => {
					locationsString = locationsString.concat(
						this.locations.find((c) => c.location_id === id).name.toLowerCase() + ","
					);
				});
				queryObject.locations = locationsString.slice(0, -1);
			}

			// Fill Filter Opening Times
			if (this.filters.openingTimes.length > 0) {
				let openingTimesString = "";
				this.filters.openingTimes.forEach((id) => {
					openingTimesString = openingTimesString.concat(
						this.openingTimes.find((c) => c.opening_time_id === id).name.toLowerCase() + ","
					);
				});
				queryObject.openingTimes = openingTimesString.slice(0, -1);
			}

			// Fill Location
			if (this.filters.location.latitude !== null) {
				queryObject.latitude = this.filters.location.latitude;
				queryObject.longitude = this.filters.location.longitude;
			}

			// Fill Location
			if (this.filters.location.formattedAddress !== "") {
				queryObject.address = this.filters.location.formattedAddress;
			}

			this.$router.replace({ query: queryObject }).catch(() => {});
		},
	},
};
