<template>
  <div
    class="c-faq-question"
    @click="toggleAnswer()"
  >
    <div
      class="c-faq-question__title"
      :class="{ 'c-faq-question__title--red': !collapsed }"
    >
      <p>{{ question.question }}</p>
      <img
        class="c-faq-question__arrow"
        :class="{'c-faq-question__arrow--rotated': !collapsed}"
        src="~@/assets/images/icons/arrow-down.svg"
        alt=""
      >
    </div>
    <transition name="fadeHeight">
      <p
        v-if="!collapsed"
        class="c-faq-question__answer"
      >
        {{ question.answer }}
      </p>
    </transition>
  </div>
</template>

<script src="./FaqQuestion.controller.js"></script>
<style lang="scss" src="./FaqQuestion.styles.scss"></style>
