<template>
  <div
    class="m-edit-modal"
    :class="{'u-hide': !showEditModal}"
  >
    <div
      class="m-edit-modal__content"
    >
      <p class="m-edit-modal__title">
        Bewerking voorstellen
      </p>
      <router-link
        :to="{ name: 'Machine-edit', params: {id: $route.params.id} }"
        class="m-edit-modal__link"
      >
        <img
          class="c-button__icon c-chip__icon"
          src="~@/assets/images/icons/edit-black.svg"
          alt="vending mapp"
        >
        <div>
          <p>Naam of andere informatie wijzigen</p>
          <span>Naam, locatie, openingstijden en meer bewerken</span>
        </div>
      </router-link>
      <button
        class="m-edit-modal__link"
        @click="openConfirmationModal()"
      >
        <img
          class="c-button__icon c-chip__icon"
          src="~@/assets/images/icons/warning-black.svg"
          alt="vending mapp"
        >
        <div>
          <p>Deze automaat is buiten gebruik</p>
          <span>Markeer dat deze automaat tijdelijk buiten gebruik is</span>
        </div>
      </button>
      <button @click="closeModal()">
        <img
          class="c-button__icon c-button__icon--close"
          src="~@/assets/images/icons/plus1.svg"
          alt="close"
        >
      </button>
    </div>
  </div>
</template>

<script src="./EditModal.controller.js">
</script>

<style lang="scss" src="./EditModal.styles.scss">
</style>
