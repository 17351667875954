import { FilterPicker, FilterSearch } from "@/components";
import { mapGetters } from "vuex";

export default {
	name: "SearchFilters",
	components: {
		FilterPicker,
		FilterSearch,
	},
	props: {},
	data: () => ({
		showFilters: false,
		filters: [
			{
				variable: "categories",
				placeholder: "Categorieën",
				searchable: false,
				icon: "type",
				isOpen: false,
			},
			{
				variable: "paymentMethods",
				placeholder: "Betaalmethoden",
				searchable: false,
				icon: "payment",
				isOpen: false,
			},
			{
				variable: "locations",
				placeholder: "Locaties",
				searchable: false,
				icon: "location",
				isOpen: false,
			},
			{
				variable: "openingTimes",
				placeholder: "Openingstijden",
				searchable: false,
				icon: "openingtimes",
				isOpen: false,
			},
		],
		destination: {
			variable: "Geef uw bestemming in",
			icon: "destination",
		},
	}),
	computed: {
		...mapGetters("machine", {
			categories: "getCategories",
		}),
	},
	methods: {
		toggleFilters() {
			this.showFilters = !this.showFilters;
		},
		toggleMobileMap() {
			this.$emit("toggleMobileMap");
		},
	},
	watch: {},
};
