<template>
  <div
    data-aos="fade-up"
    data-aos-duration="800"
    data-aos-delay="200"
    data-aos-once="true"
    class="o-row"
  >
    <div class="o-container">
      <div class="c-cta-grid">
        <div class="grid">
          <div
            class="row row-middle"
            :class="{ 'row-reverse': rowReverse }"
          >
            <div class="col-md-6">
              <h2>{{ title }}</h2>
              <p class="c-cta-grid__description">
                {{ description }}
              </p>
              <router-link
                v-if="buttonText"
                :to="{ name: 'Overview' }"
                title="Ga naar Automaten"
              >
                <button class="c-button">
                  {{ buttonText }}
                </button>
              </router-link>
            </div>
            <div class="col-md-6">
              <div class="c-cta-grid__images">
                <div
                  v-for="(img, index) in images"
                  :key="index"
                  class="cta-grid__image"
                >
                  <img
                    
                    :src="img"
                    alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./CtaGrid.controller.js"></script>
<style lang="scss" src="./CtaGrid.styles.scss"></style>