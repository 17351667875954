const MACHINE_GETTERS = {
  getMachines: (state) => state.machines,
  getMachine: (state) => state.machine,
  getCategories: (state) => state.categories,
  getFaq: (state) => state.faq,
  getPaymentMethods: (state) => state.paymentMethods,
  getLocations: (state) => state.locations,
  getOpeningTimes: (state) => state.openingTimes,
  getFirstLoad: (state) => state.firstLoad,
  getFilters: (state) => state.filters,
  getCurrentLocation: (state) => state.filters.location,
  getLoadingState: (state) => state.status.loading,
  getLoadingStateCategories: (state) => state.status.loadingCategories,
  getLoadingStatePaymentMethods: (state) => state.status.loadingPaymentMethods,
  getLoadingStateLocations: (state) => state.status.loadingLocations,
  getLoadingStateOpeningTimes: (state) => state.status.loadingOpeningTimes,
  getLoadingStateMachineForm: (state) => state.status.loadingMachineForm,
};

export default MACHINE_GETTERS;
