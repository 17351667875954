<template>
  <div class="loader">
    <div
      v-if="isLoading"
      class="spinner-container"
    >
      <svg
        class="spinner"
        :class="color"
        viewBox="0 0 50 50"
      >
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        />
      </svg>
    </div>
    <slot v-if="!isLoading" />
  </div>
</template>

<script src="./Loader.controller.js"></script>
<style lang="scss" src="./Loader.styles.scss"></style>
