import _ from 'lodash';

export default () => ({
  token: JSON.parse(localStorage.getItem('token')) || null,
  currentUser: JSON.parse(localStorage.getItem('user')) || null,
  status: {
    loading: false,
    resetPasswordLoading: false,
    forgotPasswordLoading: false,
    error: '',
  },
  registerSuccessful: false,
});
