<template>
	<vue-google-autocomplete
		id="map"
		ref="searchInput"
		classname="form-control"
		placeholder="Zoek een adres..."
		:country="['be', 'nl']"
		types="geocode"
		@placechanged="getAddressData"
	/>
</template>

<script src="./GoogleSearchLocation.controller.js"></script>
<style scoped lang="scss" src="./GoogleSearchLocation.styles.scss"></style>
