<template>
  <div class="c-chip">
    <span>09:00 - 18:00</span>
    <button>
      <img
        class="c-button__icon c-chip__icon"
        src="~@/assets/images/icons/plus.svg"
        alt="vending mapp"
      >
    </button>
  </div>
</template>

<script src="./Chip.controller.js"></script>
<style lang="scss" src="./Chip.styles.scss"></style>
