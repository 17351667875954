export default {
  inheritAttrs: false,
  name: "InputComponent",
  props: {
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String
    },
    autocomplete: {
      type: String
    }
  },
  data: () => ({
  }),
};
