export default {
  name: "EditModal",
  components: {},
  props: {
    editTitle: "",
    editDescription: "",
    editLink: "",
    showEditModal: false
  },
  data: () => ({}),
  computed: {},
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    confirm() {
      this.$emit("confirmAction");
    },
    openConfirmationModal() {
      this.$emit("openConfirmationModal");
    }
  }
};
