<template>
  <div
    class="c-tab__images"
    :class="{'carousel__no-controls': hideSlideControls}"
  >
    <swiper
      :slides-per-view="3"
      :space-between="20"
      navigation
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="(image, i) in machine.pictures"
        :key="i"
        @click="openImage(image.picture_url)"
      >
        <img :src="image.picture_url">
      </swiper-slide>
    </swiper>

    <div
      v-if="imageShown != ''"
      class="c-swiper__image"
    >
      <img
        v-for="(image, i) in machine.pictures"
        :key="i"
        :src="image.picture_url"
        :class="{ 'show' : image.picture_url === imageShown}"
      >
      <button
        class="c-swiper__image--close"
        @click="closeImage()"
      >
        <img
          src="~@/assets/images/icons/plus.svg"
          alt="close mobile menu"
        >
      </button>
    </div>
  </div>
</template>

<script src="./TabImages.controller.js"></script>
<style lang="scss" src="./TabImages.styles.scss"></style>
