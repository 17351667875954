import { api } from "@/utils";
import { useToast } from "vue-toastification";
import ACCOUNT_MUTATION_TYPES from "./account.mutation-types";

const toast = useToast();

const ACCOUNT_ACTIONS = {
	[ACCOUNT_MUTATION_TYPES.LOGIN]({ commit }, user) {
		return new Promise((resolve, reject) => {
			commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, "");
			commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, true);
			api.post("users/sign-in", user)
				.then((response) => {
					const token = response.data.access_token;
					commit(ACCOUNT_MUTATION_TYPES.SET_TOKEN, token);
					// api.get('/api/v1/users/me').then((profileResponse) => {
					//   commit(ACCOUNT_MUTATION_TYPES.SET_CURRENT_USER, profileResponse.data);
					// });
					commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, false);
					resolve();
				})
				.catch((error) => {
					let errorMessage = "Something went wrong.";

					if (error.response && error.response.data && error.response.data.detail) {
						errorMessage = error.response.data.detail;
					}

					commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, errorMessage);
					reject(error.response);
				});
		});
	},
	[ACCOUNT_MUTATION_TYPES.REGISTER]({ commit }, newUser) {
		return new Promise((resolve, reject) => {
			commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, "");
			commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, true);
			api.post("users/sign-up", newUser)
				.then(() => {
					toast.success("Account successfully created"); // TODO: ADD TRANSLATION KEY
					commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, false);
					resolve();
				})
				.catch((error) => {
					commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, errorMessage);
					reject(error);
				});
		});
	},
	[ACCOUNT_MUTATION_TYPES.FORGOT_PASSWORD]({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, "");
			commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, true);
			api.get(`users/forgot-password?email=${params.email}`)
				.then(() => {
					toast.success(`Reset password mail sent to ${params.email}.`);
					commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, false);
					resolve();
				})
				.catch((error) => {
					commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, errorMessage);
					reject(error);
				});
		});
	},
	[ACCOUNT_MUTATION_TYPES.RESET_PASSWORD]({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, "");
			commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, true);
			api.post(`users/reset-password?use_token=${params.token}`, params)
				.then(() => {
					toast.success(`Password successfully changed.`);
					commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, false);
					resolve();
				})
				.catch((error) => {
					commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, false);
					commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, errorMessage);
					reject(error);
				});
		});
	},
	[ACCOUNT_MUTATION_TYPES.LOGOUT]({ commit }) {
		return new Promise((resolve) => {
			commit(ACCOUNT_MUTATION_TYPES.REMOVE_TOKEN);
			resolve();
		});
	},
};

export default ACCOUNT_ACTIONS;
