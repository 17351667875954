export default {
  name: 'Cta',
  props:{
    imgPath: String,
    title: String,
    description: String,
    buttonText: String,
    rowReverse: Boolean
  },
  data: () => ({
  }),
};