<template>
	<div class="search-filters">
		<a class="search-filters__mobile-filter" @click="toggleFilters()">Filters</a>
		<div class="search-filters__group">
			<FilterSearch :field="destination" />
			<button class="u-desktop-show c-button c-button--white c-button--small-icon" @click="toggleMobileMap()">
				<img src="~@/assets/images/icons/map.svg" alt="map" />
			</button>
		</div>
		<FilterPicker
			v-for="filter in filters"
			:key="filter.variable"
			:class="[{ 'filter--show': showFilters }, { 'filter--active': filter.isOpen }]"
			:field="filter"
		/>
		<hr />
	</div>
</template>

<script src="./SearchFilters.controller.js"></script>
<style scoped lang="scss" src="./SearchFilters.styles.scss"></style>
