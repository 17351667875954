import _ from "lodash";
import { roundOffCoordinate } from "@/utils";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import { GoogleSearchLocation } from "@/components";
import { icon } from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
	name: "PinPointMap",
	components: {
		LMap,
		LTileLayer,
		LMarker,
		GoogleSearchLocation,
	},
	props: {
		existingMarker: null,
	},
	data: () => ({
		zoom: 14,
		center: [50.8, 4.402771],
		iconWidth: 78,
		iconHeight: 90,
		url: process.env.VUE_APP_MAPBOX_URL,
		markers: [],
		icon: icon({
			iconUrl: require("@/assets/images/icons/map-icon_default.svg"),
			iconSize: [32, 37],
			iconAnchor: [16, 37],
		}),
	}),
	computed: {},
	methods: {
		newMarker(event) {
			if (this.markers.length >= 1 && typeof event.latlng != "undefined") {
				this.markers = [];
			}

			let latLong;
			let lat;
			let long;

			if (typeof event.latlng === "undefined") {
				if (event && event.lat && event.lng) {
					lat = roundOffCoordinate(event.lat, 5);
					long = roundOffCoordinate(event.lng, 5);
					latLong = [lat, long];
					this.markers.push({
						id: this.markers.length + 1,
						latLong,
					});
					this.$emit("markerPlaced", latLong);
					this.center = latLong;
				}
			} else {
				if (event.latlng && event.latlng.lat && event.latlng.lng) {
					lat = roundOffCoordinate(event.latlng.lat, 5);
					long = roundOffCoordinate(event.latlng.lng, 5);

					latLong = [lat, long];
					this.markers.push({
						id: this.markers.length + 1,
						latLong,
					});
					this.$emit("markerPlaced", latLong);
					this.center = latLong;
				}
			}
		},
		mapDragged(event) {
			console.log(event);
		},
		zoomUpdated(zoom) {
			this.zoom = zoom;
		},
		centerUpdated(center) {
			this.center = center;
		},
		iconSize() {
			return [this.iconWidth, this.iconHeight];
		},
		addressInputChanged(addressInfo) {
			this.newMarker({ lat: addressInfo.latitude, lng: addressInfo.longitude });
		},
	},
	created() {
		if (!_.isEmpty(this.existingMarker)) {
			this.newMarker({ lat: this.existingMarker.latLong[0], lng: this.existingMarker.latLong[1] });
		}
	},
};
