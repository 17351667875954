<template>
  <div class="c-tab__info">
    <div class="grid">
      <div class="row">
        <div
          v-for="method in paymentMethods"
          :key="method.payment_method_id"
        >
          <label
            :key="`${method.payment_method_id}-label`"
            class="a-input__checkbox-container"
          >
            {{ method.name }}
            <input
              :checked="machine.payment_methods.map(pm => pm.payment_method_id).flat().includes(method.payment_method_id)"
              type="checkbox"
              disabled
            >
            <span class="a-input__checkbox-checkmark" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./TabPayment.controller.js"></script>
<style lang="scss" src="./TabPayment.styles.scss"></style>
