import axios from "axios";
import _ from "lodash";
import { useToast } from "vue-toastification";
import router from '../router/index';
import { store } from "../store";

const toast = useToast();

const baseURL = () => {
  let baseUrl = "";
  if (process.env.VUE_APP_API === "api") {
    baseUrl = `${window.location.protocol}//${window.location.hostname}/api`;
  } else {
    baseUrl = process.env.VUE_APP_API;
  }

  return baseUrl;
};

// Step 1: Create a new Axios instance
const api = axios.create({
  baseURL: baseURL(),
  timeout: 360000
  // headers: {
  //   Authorization: `Bearer ${_.get(JSON.parse(localStorage.getItem("token")), "token", "")}`
  // }
});

// Step 2: Create request, response & error handlers
const requestHandler = request => {
  request.headers.Authorization = `Bearer ${_.get(JSON.parse(localStorage.getItem("token")), "token", "")}`;

  return request;
};

const responseHandler = response => {
  if (response.status === 401) {
    store.dispatch("account/LOGOUT");
    router.push({ name: "Login" })
  }

  return response;
};

const errorHandler = error => {
  console.log(error.response);
  if (error.response.status === 401) {
    store.dispatch("account/LOGOUT");
    router.push({ name: "Login" })
  }

  toast.error(`Error: ${error.response.data.detail}`);

  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
api.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

api.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
 );

export { api };
