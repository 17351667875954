import {} from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "TabOpening",
  components: {},
  props: {},
  data: () => ({
    hours: [
      {
        day: "Maandag",
        hours: "09:00 - 18:00"
      },
      {
        day: "Dinsdag",
        hours: "09:00 - 12:00 / 13:00 - 18:00"
      },
      {
        day: "Woensdag",
        hours: "09:00 - 18:00 / 13:00 - 18:00"
      },
      {
        day: "Donderdag",
        hours: "09:00 - 18:00 / 13:00 - 18:00"
      },
      {
        day: "Vrijdag",
        hours: "09:00 - 18:00"
      },
      {
        day: "Zaterdag",
        hours: "09:00 - 18:00"
      },
      {
        day: "Zondag",
        hours: "gesloten"
      }
    ]
  }),
  computed: {
    ...mapGetters("machine", {
      machine: "getMachine",
    }),
  },
  methods: {},
  created() {}
};
