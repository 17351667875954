<template>
	<div class="o-row o-row--light">
		<div class="o-container">
			<div class="c-footer-secondary">
				<div class="c-footer-secondary__nav">
					<ul>
						<li>
							<router-link :to="{ name: 'Faq' }" title="Faq"> Frequently Asked Questions </router-link>
						</li>
						<li>
							<router-link :to="{ name: 'Overview' }" title="Ga naar Automaten"> Automaten </router-link>
						</li>
						<li>
							<router-link :to="{ name: 'TermsAndConditions' }" title="Algemene voorwaarden">
								Algemene voorwaarden
							</router-link>
						</li>
						<li><a href="mailto:info@vendingmapp.com">Contact</a></li>
					</ul>
				</div>
				<p>&copy; 2021 De Maerteleire Invest</p>
			</div>
		</div>
	</div>
</template>

<script src="./FooterSecondary.controller.js"></script>
<style lang="scss" src="./FooterSecondary.styles.scss"></style>
