export default {
  name: "FaqQuestion",
  props: {
    question: Object
  },
  data: () => ({
    collapsed: true
  }),
  methods: {
    toggleAnswer() {
      this.collapsed = !this.collapsed;
    }
  }
};
