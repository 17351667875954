import { Flyout, Hamburger, Language } from "@/components";

export default {
  name: "Header",
  components: {
    Hamburger,
    Flyout,
    Language
  },
  props: {},
  data: () => ({
    showFlyout: false,
    error: false
  }),
  computed: {
    defaultHeader() {
      return this.$route.name !== "Home";
    },
    headerLinks() {
      return [
        {
          title: "Automaten",
          pathName: "Overview",
          show: true
        },
        {
          title: "Premium",
          pathName: "Premium",
          show: true
        },
        {
          title: "Log In",
          pathName: "Login",
          show: !this.$isLoggedIn
        },
        {
          title: "Account",
          pathName: "ChangePassword", // TODO: Favorites
          show: this.$isLoggedIn
        }
      ].filter(l => l.show);
    }
  },
  methods: {
    toggleFlyout() {
      this.showFlyout = !this.showFlyout;
    },
    isActiveLink(pathName) {
      if (pathName === this.$route.name) {
        return true;
      }

      if (pathName === "Overview" && this.$route.name === "Detail") {
        return true;
      }
    }
  },
  created() {}
};
