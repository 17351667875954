
import { mapActions, mapMutations, mapGetters } from "vuex";
import { GoogleSearchLocation } from "@/components";

export default {
  name: 'FilterSearch',
  components: {
    GoogleSearchLocation
  },
  props: {
    field: {},
  },
  data: () => ({
    value: ""
  }),
  computed: {
    ...mapGetters("machine", {
      currentLocation: "getCurrentLocation",
      filters: "getFilters"
    }),
  },
  methods: {
    ...mapMutations("machine", {
      setSearchLocation: "SET_SEARCH_LOCATION",
      setMachineFilter: "SET_MACHINE_FILTERS",
    }),
    ...mapActions("machine", {
      fetchMachines: "FETCH_DATA_MACHINES",
    }),
    updateSelectedCategory(e){
      this.setMachineFilter(this.categories.filter(c => c.category_id === e));
    },
    getCurrentLocation() {
      this.$refs.googleSearchLocation.getCurrentLocation();
      this.fetchMachines(this.filters);
    },
  },
  created(){
    this.value = this.currentLocation.formattedAddress;
  }
};
