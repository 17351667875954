import { FilterHead, Loader } from "@/components";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { ref } from "vue";
import { onClickOutside, onKeyStroke } from "@vueuse/core";

export default {
	name: "FilterPicker",
	components: {
		FilterHead,
		Loader,
	},
	props: {
		field: {},
	},
	data: () => ({
		filterHead: "Origin",
		terminalList: [],
		originString: "",
		selectedTypes: [],
		selectedPaymentMethods: [],
		type: {
			variable: "Type",
			searchable: false,
			icon: require("@/assets/images/icons/type.svg"),
			isOpen: false,
		},
		payment: {
			variable: "PaymentMethods",
			searchable: false,
			icon: require("@/assets/images/icons/payment.svg"),
			isOpen: false,
		},
		location: {
			variable: "Locations",
			searchable: false,
			icon: require("@/assets/images/icons/location.svg"),
			isOpen: false,
		},
		opening: {
			variable: "OpeningTimes",
			searchable: false,
			icon: require("@/assets/images/icons/openingtimes.svg"),
			isOpen: false,
		},
		destination: {
			variable: "Geef uw bestemming in",
			icon: require("@/assets/images/icons/destination.svg"),
		},
	}),
	computed: {
		...mapGetters("machine", {
			filters: "getFilters",
			categories: "getCategories",
			paymentMethods: "getPaymentMethods",
			locations: "getLocations",
			openingTimes: "getOpeningTimes",
			loadingStateCategories: "getLoadingStateCategories",
			loadingStatePaymentMethods: "getLoadingStatePaymentMethods",
			loadingStateLocations: "getLoadingStateLocations",
			loadingStateOpeningTimes: "getLoadingStateOpeningTimes",
		}),
		filterState() {
			const currentVariable = this.field.variable;
			if (this.filters[currentVariable] !== undefined && this.filters[currentVariable].length > 0) return true;
		},
		fields() {
			if (this.field.variable === "categories") return this.categories;
			if (this.field.variable === "paymentMethods") return this.paymentMethods;
			if (this.field.variable === "locations") return this.locations;
			if (this.field.variable === "openingTimes") return this.openingTimes;
		},
		isLoading() {
			return false;
		},
	},
	methods: {
		...mapMutations("machine", {
			setMachineFilters: "SET_MACHINE_FILTERS",
			clearMachineFilter: "CLEAR_MACHINE_FILTER",
		}),
		...mapActions("machine", {
			fetchMachines: "FETCH_DATA_MACHINES",
		}),
		toggleFilter() {
			this.field.isOpen = !this.field.isOpen;
		},
		updateFilters(e) {
			this.setMachineFilters(e);
		},
		setFilter() {
			this.fetchMachines(this.filters);
			this.toggleFilter(); // Close menu after setting
		},
		clearFilter() {
			this.clearMachineFilter(this.field);
			this.setFilter();
		},
	},
	created() {},
	setup(props, context) {
		const target = ref(null);

		onClickOutside(target, (event) => {
			props.field.isOpen = false;
		});

		return { target };
	},
};
