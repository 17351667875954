import moment from "moment";
import { formatDate, formatPrice } from "@/utils";
import { Tag } from "@/components";

export default {
  name: "InvoiceTable",
  components: {
    Tag
  },
  props: {
    headers: Array,
    data: Array
  },
  data: () => ({}),
  methods: {
    formatDate,
    formatPrice
  }
};
