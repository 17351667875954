import Multiselect from "@vueform/multiselect";

export default {
  name: "Language",
  components: { Multiselect },
  data: () => ({
    // Must have a "value" prop
    languages: [
      {
        value: 1,
        name: "Nederlands",
        abbreviation: "NL",
        isoCode: "nl"
      },
      {
        value: 2,
        name: "French",
        abbreviation: "FR",
        isoCode: "fr"
      },
      {
        value: 3,
        name: "English",
        abbreviation: "EN",
        isoCode: "gb"
      },
      // {
      //   value: 4,
      //   name: "Nederlands",
      //   abbreviation: "NL",
      //   isoCode: "nl"
      // },
      // {
      //   value: 5,
      //   name: "French",
      //   abbreviation: "FR",
      //   isoCode: "fr"
      // },
      // {
      //   value: 6,
      //   name: "English",
      //   abbreviation: "EN",
      //   isoCode: "gb"
      // }
    ],
    currentLanguage: 1
  })
};
