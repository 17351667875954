import { Chip } from '@/components';

export default {
  name: 'OpeningTimes',
  components:{
    Chip
  },
  data: () => ({
    openingTimes:[{
      name: "Monday",
      periods:["09:00 - 18:00"],
      closed: false,
    },
    {
      name: "Tuesday",
      periods:["09:00 - 18:00"],
      closed: false,
    },
    {
      name: "Wednesday",
      periods:["09:00 - 18:00"],
      closed: false,
    },
    {
      name: "Thursday",
      periods:["09:00 - 18:00"],
      closed: false,
    },
    {
      name: "Friday",
      periods:["09:00 - 18:00"],
      closed: false,
    },
    {
      name: "Saturday",
      periods:["09:00 - 18:00"],
      closed: false,
    },
    {
      name: "Sunday",
      periods:["09:00 - 18:00"],
      closed: false,
    }
  ]})
};