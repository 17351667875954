<template>
  <div class="c-invoice-table">
    <table class="c-table">
      <thead>
        <tr>
          <th
            v-for="head in headers"
            :key="head.name"
          >
            {{ head.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in data"
          :key="row"
        >
          <td class="c-invoice-table__invoiceNr">
            {{ row.invoiceNr }}
          </td>
          <td>{{ formatDate(row.date) }}</td>
          <td>{{ row.description }}</td>
          <td>{{ formatPrice(row.total) }}</td>
          <td class="c-invoice-table__actions">
            <img
              src="~@/assets/images/icons/download.svg"
              alt="delete"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script src="./InvoiceTable.controller.js"></script>
<style lang="scss" src="./InvoiceTable.styles.scss"></style>
