import MACHINE_INITIAL_STATE from './machine.initial-state';
import MACHINE_MUTATIONS from './machine.mutations';
import MACHINE_GETTERS from './machine.getters';
import MACHINE_ACTIONS from './machine.actions';

/* ==========================================================================
   MACHINE MODULE STORE
   ========================================================================== */
const machineStore = {
  name: 'machine',
  namespaced: true,
  state: () => MACHINE_INITIAL_STATE(),
  mutations: MACHINE_MUTATIONS,
  actions: MACHINE_ACTIONS,
  getters: MACHINE_GETTERS,
};


export { machineStore };
