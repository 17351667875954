import {} from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "TabDetails",
  components: {},
  props: {},
  data: () => ({
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vel pulvinar lectus, id suscipit ex. Etiam id placerat ipsum. Quisque consectetur elit eu cursus suscipit. Quisque pharetra sed lacus sit amet consequat.\n- lorem\n- ipsum\n- dolor\n"
  }),
  computed: {
    ...mapGetters("machine", {
      machine: "getMachine",
    }),
  },
  methods: {},
  created() {}
};
