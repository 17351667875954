import MACHINE_MUTATION_TYPES from "./machine.mutation-types";
import initialState from "./machine.initial-state";
import { translateCategory, translateLocation, translateOpeningTime } from "@/utils";

const MACHINE_MUTATIONS = {
	[MACHINE_MUTATION_TYPES.APPEND_MACHINES](state, machines) {
		state.machines.push(...machines);
	},
	[MACHINE_MUTATION_TYPES.CLEAR_MACHINES](state, machines) {
		state.machines = [];
	},
	[MACHINE_MUTATION_TYPES.TOGGLE_FIRST_LOAD](state) {
		state.firstLoad = true;
	},
	[MACHINE_MUTATION_TYPES.SET_MACHINE](state, machine) {
		state.machine = machine;
	},
	[MACHINE_MUTATION_TYPES.SET_SEARCH_LOCATION](state, location) {
		state.filters.location = location;
	},
	[MACHINE_MUTATION_TYPES.SET_ERROR](state, error) {
		state.status.loading = false;
		state.status.error = error;
	},
	[MACHINE_MUTATION_TYPES.SET_CATEGORIES](state, categories) {
		const cat = categories.map((c) => {
			return { ...c, name: translateCategory(c.name) };
		});

		state.categories = cat;
		// state.categories = categories;
	},
	[MACHINE_MUTATION_TYPES.SET_LOCATIONS](state, locations) {
		const loc = locations.map((l) => {
			return { ...l, name: translateLocation(l.name) };
		});

		state.locations = loc;
	},
	[MACHINE_MUTATION_TYPES.SET_FAQ](state, faq) {
		state.faq = faq;
	},
	[MACHINE_MUTATION_TYPES.SET_PAYMENT_METHODS](state, paymentMethods) {
		state.paymentMethods = paymentMethods;
	},
	[MACHINE_MUTATION_TYPES.SET_OPENING_TIMES](state, openingTimes) {
		const translatedOpeningTimes = openingTimes.map((ot) => {
			return { ...ot, name: translateOpeningTime(ot.name) };
		});

		state.openingTimes = translatedOpeningTimes;
	},
	[MACHINE_MUTATION_TYPES.CLEAR_MACHINE_FILTER](state, field) {
		if (field.variable === "categories") {
			state.filters.categories = initialState().filters.categories;
		}
		if (field.variable === "paymentMethods") {
			state.filters.paymentMethods = initialState().filters.paymentMethods;
		}
	},
	[MACHINE_MUTATION_TYPES.CLEAR_MACHINE_FILTERS](state) {
		state.filters.categories = initialState().filters.categories;
		state.filters.paymentMethods = initialState().filters.paymentMethods;
		state.filters.locations = initialState().filters.locations;
	},
	[MACHINE_MUTATION_TYPES.SET_MACHINE_FILTERS](state, filter) {
		if (filter.hasOwnProperty("category_id")) {
			if (state.filters.categories.includes(filter.category_id)) {
				const index = state.filters.categories.indexOf(filter.category_id);
				if (index !== -1) {
					state.filters.categories.splice(index, 1);
				}
			} else {
				state.filters.categories.push(filter.category_id);
			}
		}
		if (filter.hasOwnProperty("payment_method_id")) {
			if (state.filters.paymentMethods.includes(filter.payment_method_id)) {
				const index = state.filters.paymentMethods.indexOf(filter.payment_method_id);
				if (index !== -1) {
					state.filters.paymentMethods.splice(index, 1);
				}
			} else {
				state.filters.paymentMethods.push(filter.payment_method_id);
			}
		}
		if (filter.hasOwnProperty("location_id")) {
			if (state.filters.locations.includes(filter.location_id)) {
				const index = state.filters.locations.indexOf(filter.location_id);
				if (index !== -1) {
					state.filters.locations.splice(index, 1);
				}
			} else {
				state.filters.locations.push(filter.location_id);
			}
		}
		if (filter.hasOwnProperty("opening_time_id")) {
			if (state.filters.openingTimes.includes(filter.opening_time_id)) {
				const index = state.filters.openingTimes.indexOf(filter.opening_time_id);
				if (index !== -1) {
					state.filters.openingTimes.splice(index, 1);
				}
			} else {
				state.filters.openingTimes.push(filter.opening_time_id);
			}
		}
	},
	[MACHINE_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
		state.status.loading = isLoading;
	},
	[MACHINE_MUTATION_TYPES.SET_LOADING_STATE_CATEGORIES](state, isLoading) {
		state.status.loadingCategories = isLoading;
	},
	[MACHINE_MUTATION_TYPES.SET_LOADING_STATE_MACHINE_FORM](state, isLoading) {
		state.status.loadingMachineForm = isLoading;
	},
	[MACHINE_MUTATION_TYPES.SET_LOADING_STATE_PAYMENT_METHODS](state, isLoading) {
		state.status.loadingPaymentMethods = isLoading;
	},
	[MACHINE_MUTATION_TYPES.SET_LOADING_STATE_LOCATIONS](state, isLoading) {
		state.status.loadingLocations = isLoading;
	},
	[MACHINE_MUTATION_TYPES.SET_LOADING_STATE_OPENING_TIMES](state, isLoading) {
		state.status.openingTimes = isLoading;
	},
};

export default MACHINE_MUTATIONS;
