<template>
  <div
    class="m-confirmation-modal"
    :class="{'u-hide': !showConfirmationModal}"
  >
    <div
      class="m-confirmation-modal__content"
    >
      <p class="m-confirmation-modal__title">
        {{ confirmationTitle }}
      </p>
      <p class="m-confirmation-modal__description">
        {{ confirmationDescription }}
      </p>
      <a
        v-if="confirmationLink"
        :href="confirmationLink"
        class="m-confirmation-modal__link"
      >
        {{ confirmationLink }}
      </a>
      <div class="m-confirmation-modal__confirmation-buttons">
        <button
          class="c-button c-button--secondary"
          href="#"
          title="Annuleer"
          @click="closeModal()"
        >
          Annuleer
        </button>
        <button
          type="button"
          class="c-button"
          @click="confirm()"
        >
          Bevestig
        </button>
      </div>
    </div>
  </div>
</template>

<script src="./ConfirmationModal.controller.js">
</script>

<style lang="scss" src="./ConfirmationModal.styles.scss">
</style>
