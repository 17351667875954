import { Header, Footer } from "@/components";

import { mapMutations, mapGetters } from "vuex";
import { updateRouteQueryByState } from "./mixins/UpdateRouteQueryByState";
import AOS from "aos";
import "aos/dist/aos.css";
import _ from "lodash";

export default {
	name: "App",
	components: {
		Header,
		Footer,
	},
	mixins: [updateRouteQueryByState],
	props: {},
	data: () => ({
		version: process.env.VUE_APP_VERSION,
	}),
	computed: {
		...mapGetters("machine", {
			filters: "getFilters",
			categories: "getCategories",
			paymentMethods: "getPaymentMethods",
		}),
	},
	methods: {

		...mapMutations("machine", {
			clearMachineFilters: "CLEAR_MACHINE_FILTERS",
			toggleFirstLoad: "TOGGLE_FIRST_LOAD",
		}),
	},
	watch: {
		$route(to, from) {
			if (to.path !== from.path) {
				const home_to_overview = from.name.toLowerCase() == "home" && to.name.toLowerCase() == "overview";
				const detail_to_overview = from.name.toLowerCase() == "detail" && to.name.toLowerCase() == "overview";
				const overview_to_detail = from.name.toLowerCase() == "overview" && to.name.toLowerCase() == "detail";

				if (!home_to_overview && !detail_to_overview && !overview_to_detail) {
					this.clearMachineFilters();
				} else if (home_to_overview || detail_to_overview) {
					this.updateRouteQueryByState();
					this.toggleFirstLoad();
				}
			}
		},
	},
	created() {
		AOS.init();
	},
};
