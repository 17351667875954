import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Auth from "./mixins/Auth";

// Toast plugin:
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

// Pagination
import Pagination from "v-pagination-3";

const app = createApp(App).use(store).use(router).use(Toast).component("pagination", Pagination).mixin(Auth);

router.isReady().then(() => {
	app.mount("#app");
});
