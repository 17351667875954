import {} from "@/components";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TabPayment",
  components: {},
  props: {},
  data: () => ({
    methods: [
      {
        id: 1,
        name: "Munten",
        checked: true
      },
      {
        id: 2,
        name: "Briefjes",
        checked: false
      },
      {
        id: 3,
        name: "Bancontact",
        checked: false
      },
      {
        id: 4,
        name: "Contactloos",
        checked: true
      },
      {
        id: 5,
        name: "Apple Pay",
        checked: true
      }
    ],
    checked: true,
    unchecked: false
  }),
  computed: {
    ...mapGetters("machine", {
      machine: "getMachine",
      paymentMethods: "getPaymentMethods"
    }),
  },
  methods: {
    ...mapActions("machine", {
      fetchPaymentMethods: "FETCH_PAYMENT_METHODS"
    })
  },
  created() {
    this.fetchPaymentMethods();
  }
};
