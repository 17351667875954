<template>
	<l-map
		ref="map"
		:zoom="initialZoom"
		:center="initialCenter"
		:min-zoom="8"
		:max-zoom="20"
		@update:zoom="zoomUpdated"
		@update:center="centerUpdated"
	>
		<l-tile-layer :url="url" />
		<l-marker
			v-for="machine in machines"
			:key="machine.vending_machine_id"
			:lat-lng="[machine.coordinates.latitude, machine.coordinates.longitude]"
			@click="openCard(machine.vending_machine_id)"
		>
			<l-icon
				:icon-url="mapIcon(machine)"
				:icon-size="iconSize(machine)"
				:class-name="machine.out_of_use ? 'u-opacity-50' : ''"
			/>
		</l-marker>
	</l-map>
	<button v-if="buttonRefreshIsActive" class="c-overview__map--button" @click="searchHere()">
		<img src="@/assets/images/icons/rotate-cw.svg" alt="loading" />
		Search here
	</button>
</template>

<script src="./OverviewMap.controller.js"></script>
<style lang="scss" src="./OverviewMap.styles.scss"></style>
