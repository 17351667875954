import {
	LMap,
	LIcon,
	LTileLayer,
	LMarker,
	LControlLayers,
	LTooltip,
	LPopup,
	LPolyline,
	LPolygon,
	LRectangle,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

import { mapGetters } from "vuex";

export default {
	name: "OverviewMap",
	components: {
		LMap,
		LIcon,
		LTileLayer,
		LMarker,
		LControlLayers,
		LTooltip,
		LPopup,
		LPolyline,
		LPolygon,
		LRectangle,
	},
	data() {
		return {
			initialZoom: 9,
			initialCenter: [50.87184477102432, 3.632355723073411],
			zoom: 9,
			center: [50.87184477102432, 3.632355723073411],
			bounds: null,
			iconWidth: 18,
			iconHeight: 21,
			iconWidthPremium: 39,
			iconHeightPremium: 45,
			url: process.env.VUE_APP_MAPBOX_URL,
			selectedMachine: {},
			buttonRefreshIsActive: false,
			initialSearchHappened: false,
		};
	},
	watch: {
		"filters.location": {
			handler() {
				if (this.filters.location.latitude !== null) {
					// Focus map on new location:
					if (!this.initialSearchHappened) {
						// this.$refs.map.leafletObject.setZoom(15);
						this.initialSearchHappened = true;
					}
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters("machine", {
			machines: "getMachines",
			filters: "getFilters",
		}),
	},
	methods: {
		iconSize(machine) {
			if (machine.is_premium) {
				return [this.iconWidthPremium, this.iconHeightPremium];
			}

			return [this.iconWidth, this.iconHeight];
		},
		mapIcon(machine) {
			if (machine.is_premium) {
				return require("@/assets/images/icons/map-icon_strawberry.svg");
			}

			return require("@/assets/images/icons/map-icon_default.svg");
		},
		openCard(machineId) {
			this.$emit("openCard", machineId);
		},
		zoomUpdated(zoom) {
			this.zoom = zoom;
			this.buttonRefreshIsActive = true;
		},
		centerUpdated(center) {
			this.center = center;
			this.buttonRefreshIsActive = true;
		},
		searchHere() {
			// Get latlong from current map postition (middle)
			this.buttonRefreshIsActive = false;
			this.$emit("fetchMachinesMapCenter", this.center);
		},
	},
	created() {
		if (this.filters && this.filters.location && this.filters.location.latitude !== null) {
			this.initialZoom = 15;
			this.zoom = 15;
			this.initialCenter = [this.filters.location.longitude, this.filters.location.latitude];
			this.center = [this.filters.location.longitude, this.filters.location.latitude];
		} else if (this.$route.query.longitude && this.$route.query.latitude) {
			this.initialZoom = 15;
			this.zoom = 15;
			this.initialCenter = [this.$route.query.longitude, this.$route.query.latitude];
			this.center = [this.$route.query.longitude, this.$route.query.latitude];
		}
	},
};
