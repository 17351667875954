import { createStore } from 'vuex'
import { STORE_MODULES } from './store-modules';

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => ({}),
  mutations: {},
  actions: {},
  modules: STORE_MODULES,
})

export { store }