import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LTooltip,
  LPopup,
  LPolyline,
  LPolygon,
  LRectangle
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

import { mapGetters } from "vuex";

export default {
  name: "DetailMap",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LTooltip,
    LPopup,
    LPolyline,
    LPolygon,
    LRectangle
  },
  data: () => ({
    zoom: 14,
    iconWidth: 18,
    iconHeight: 21,
    iconWidthPremium: 39,
    iconHeightPremium: 45,
    center: [50.8, 4.402771],
    url: process.env.VUE_APP_MAPBOX_URL,
    // TODO: machines will come from the store
    selectedMachine: {},
  }),
  computed: {
    ...mapGetters("machine", {
      machine: "getMachine",
    }),
  },
  methods: {
    iconSize(machine) {
      if (machine.verified) {
        return [this.iconWidthPremium, this.iconHeightPremium];
      }

      return [this.iconWidth, this.iconHeight];
    },
    mapIcon(machine) {
      if (machine.verified) {
        return require("@/assets/images/icons/map-icon_strawberry.svg");
      }

      return require("@/assets/images/icons/map-icon_default.svg");
    },
    openCard(machineId) {
      this.$emit("openCard", machineId);
    }
  }
};
