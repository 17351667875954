<template>
  <div class="c-checkout__calculator">
    <div>
      <img
        class="c-checkout__calculator--image"
        src="~@/assets/images/puzzlepremium-large.png"
        alt="premium"
      >
    </div>
                
    <h4>Premium</h4>
    <span>Hoeveel automaten heeft u?</span>
    <div class="c-checkout__calculator--counter">
      <button>
        <img
          src="~@/assets/images/icons/minus.svg"
          alt="premium"
        >
      </button>
      <span>7</span>
      <button>
        <img
          src="~@/assets/images/icons/plus1.svg"
          alt="premium"
        >
      </button>
    </div>
    <hr class="c-hr--dark">
    <div class="c-checkout__calculator--results">
      <div class="c-checkout__calculator--price">
        <span class="c-checkout__calculator--price-value">€ {{ price }},00</span>
        <span class="c-checkout__calculator--price-divider">/</span>
        <div class="c-checkout__calculator--price-period">
          <span>automaat</span>
          <span>per jaar</span>
        </div>
      </div>
      <div class="c-checkout__calculator--price">
        <span class="c-checkout__calculator--price-value">€ {{ price }},00</span>
        <div class="c-checkout__calculator--price-period">
          <span>&nbsp; totaal</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./PremiumCalculator.controller.js"></script>
<style lang="scss" src="./PremiumCalculator.styles.scss"></style>