<template>
  <div class="c-radio">
    <p>name: {{ field }}</p>
    <p>value: {{ item[value] }}</p>
    <p>$attrs: {{ $attrs }}</p>
    <input
      :id="item[value]"
      type="radio"
      :name="field"
      :value="item[value]"
      v-bind="$attrs"
      :selected-value="item[value]"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <label
      class="c-radio__button"
      :for="item[value]"
    >
      <div class="c-radio__button--icon">
        <div class="c-radio__button--icon-indicator" />
      </div>
      <span class="c-radio__button--label">{{ item[label] }}</span>
    </label>
  </div>
</template>

<script src="./RadioButtonComponent.controller.js"></script>
<style lang="scss" src="./RadioButtonComponent.styles.scss"></style>
