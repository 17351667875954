export default {
  name: 'ConfirmationModal',
  components: {
  },
  props: {
    confirmationTitle: '',
    confirmationDescription: '',
    confirmationLink: '',
    showConfirmationModal: false,
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
    closeModal() {
      this.$emit('closeConfirmationModal');
    },
    confirm() {
      this.$emit('confirmAction');
    },
  },
};
