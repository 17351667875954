export default {
  name: "FilterHead",
  components: {},
  props: {
    filterHead: "",
    filterIcon: "",
    isOpen: false,
    isActive: false
  },
  emits: ["openFilter"],
  data: () => ({}),
  computed: {},
  methods: {
    changeOpenState() {
      this.$emit("openFilter");
    },
    clickOutside() {
      this.$emit("openFilter");
    }
  },
  created() {}
};
