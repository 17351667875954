import { Language } from "@/components";

export default {
  name: "FooterPrimary",
  components: {
    Language
  },
  data: () => ({
    rowReverse: true
  })
};
