<template>
	<div class="o-row o-row--sm">
		<div class="o-container--lg">
			<div class="c-hero">
				<div data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
					<div class="c-hero__logo">
						<img src="~@/assets/images/logo-white.svg" alt="vending mapp" />
					</div>
					<h1>Vind verkoopsautomaten in uw buurt.</h1>
					<p>Broden, aardbeien, verse voeding, frisdrank... en nog veel meer.</p>
				</div>
				<div class="c-hero__image">
					<img src="~@/assets/images/home.jpg" alt="home" />
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Hero.controller.js"></script>
<style lang="scss" src="./Hero.styles.scss"></style>
