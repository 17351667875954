import {
  TabPayment,
  TabOpening,
  TabImages,
  TabDetails
} from '@/components';

import { mapGetters } from "vuex";

export default {
  name: 'Tabs',
  components: {
    TabPayment,
    TabOpening,
    TabImages,
    TabDetails
  },
  props: {},
  data: () => ({
    activeItem: 'details'
  }),
  computed: {
    ...mapGetters("machine", {
      machine: "getMachine"
    })
  },
  methods: {
    setActive (menuItem) {
      this.activeItem = menuItem;
      this.$refs.tabImages.initSwiper();
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
  },
  created() {
  },
};
