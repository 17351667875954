<template>
  <div class="c-machine-table">
    <table class="c-table">
      <thead>
        <tr>
          <th
            v-for="head in headers"
            :key="head.name"
          >
            {{ head.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in data"
          :key="row"
        >
          <td class="c-machine-table__name">
            {{ row.name }}
          </td>
          <td class="c-machine-table__address">
            {{ formatAddress(row.address) }}
          </td>
          <td class="c-machine-table__premium">
            {{ formatDate(row.premiumUntil) }}
          </td>
          <td class="c-machine-table__categories">
            <div class="c-machine-table__categories-wrapper">
              <Tag
                v-for="tag in row.categories"
                :key="tag.category_id"
                :tag="tag"
              />
            </div>
          </td>
          <td class="c-machine-table__actions">
            <div class="c-machine-table__actions-wrapper">
              <img
                src="~@/assets/images/icons/delete.svg"
                alt="delete"
              >
              <img
                src="~@/assets/images/icons/edit.svg"
                alt="edit"
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script src="./MachineTable.controller.js"></script>
<style lang="scss" src="./MachineTable.styles.scss"></style>
