import ACCOUNT_MUTATION_TYPES from "./account.mutation-types";

const ACCOUNT_MUTATIONS = {
  [ACCOUNT_MUTATION_TYPES.SET_TOKEN](state, token) {
    const item = {
      token,
      expiry: new Date().getTime() + 86400000 // 1 day
    };

    localStorage.setItem("token", JSON.stringify(item));
    state.token = token;
  },
  [ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
    state.status.loading = isLoading;
  },
  [ACCOUNT_MUTATION_TYPES.SET_ERROR](state, error) {
    state.status.loading = false;
    state.status.error = error;
  },
  [ACCOUNT_MUTATION_TYPES.REMOVE_TOKEN](state) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    state.token = "";
    state.currentUser = null;
  }
};

export default ACCOUNT_MUTATIONS;
