export default {
	name: "Flyout",
	components: {},
	props: {
		showFlyout: false,
	},
	data: () => ({}),
	computed: {
		defaultHeader() {
			return this.$route.name !== "Home";
		},
		headerLinks() {
			return [
				{
					title: "Automaten",
					pathName: "Overview",
					show: true,
				},
				{
					title: "Premium",
					pathName: "Premium",
					show: true,
				},
				{
					title: "Log In",
					pathName: "Login",
					show: !this.$isLoggedIn,
				},
				{
					title: "Account",
					pathName: "ChangePassword", // TODO: Favorites
					show: this.$isLoggedIn,
				},
			].filter((l) => l.show);
		},
	},
	methods: {
		isActiveLink(pathName) {
			if (pathName === this.$route.name) {
				return true;
			}

			if (pathName === "Overview" && this.$route.name === "Detail") {
				return true;
			}
		},
		toggleFlyout() {
			this.$emit("hamburgerClicked");
		},
	},
};
