import { Tag } from "@/components";
import { translateOpeningTime } from "@/utils";

export default {
	name: "MachineCard",
	components: {
		Tag,
	},
	props: {
		machine: Object,
	},
	data: () => ({
		// Todo: replace this with list from current user (propebly via store)
		favourites: [{ id: 1 }, { id: 3 }],
	}),
	computed: {
		isVerified() {
			// return true;
			return this.machine.verified;
		},
		determineFavIcon() {
			//Todo: look if user has this icon in his favourite list:
			if (this.favourites.filter((f) => f.id === this.machine.id).length > 0) {
				return require("@/assets/images/icons/heart_red.svg");
			}
			return require("@/assets/images/icons/heart_gray.svg");
		},
		tags() {
			// Todo return tags from api
			return [
				{
					id: 1,
					name: "Aardbeien",
					backgroundColor: "#F6CACA",
					color: "#DF3D3C",
				},
				{
					id: 2,
					name: "Verse maaltijden",
					backgroundColor: "#DEF5D1",
					color: "#7DD747",
				},
			];
		},
		visibleCategories() {
			const categories = []; // Create empty list of categories

			if (this.machine.main_category) {
				categories.push(this.machine.main_category); // Add Main category
			}

			if (this.machine.categories) {
				// Add 2 more categories so we show a maximum of 3 categories per card
				categories.push(...this.machine.categories.slice(0, 2));
			}

			// Add extra TAG [+x] with the remaining amount of (hidden) tags
			const remainingCategoryCount = this.machine.categories.length - this.machine.categories.slice(0, 2).length;
			if (remainingCategoryCount > 0) {
				categories.push({
					name: `${remainingCategoryCount}+`,
					background_color: "#E6E6E6",
					color: "#404040",
					category_id: 999,
				});
			}

			return categories;
		},
	},
	methods: {
		translateOpeningTime,
		toggleFavourite() {
			// Todo: make a call to the backend

			//Dummy code for now:
			if (this.favourites.filter((f) => f.id === this.machine.id).length > 0) {
				this.favourites.splice(
					this.favourites.findIndex((f) => f.id === this.machine.id),
					1
				);
			} else {
				this.favourites.push({ id: this.machine.id });
			}
		},
		mToKm(meters) {
			const km = meters / 1000;
			if (meters > 1000) return km.toFixed(1) + " Km";
			else return meters.toFixed(0) + " m";
		},
	},
	created() {},
};
