import { createRouter, createWebHistory } from "vue-router";
import { store } from "../store";

const checkAuth = () => {
  var token = JSON.parse(localStorage.getItem("token")) || null;

  if (token && token.expiry) {
    if (token.expiry - Date.now() < 0) {
      store.dispatch("account/LOGOUT");
      return false;
    }
    return true;
  }
  return false;
};

const ifNotAuthenticated = (to, from, next) => {
  if (!checkAuth()) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (checkAuth()) {
    next();
    return;
  }
  next("/login");
};

const logOut = (to, from, next) => {
  if (checkAuth()) {
    store.dispatch("account/LOGOUT").then(() => {
      next("/login");
    });
    window.location.reload();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home/Home.vue"),
    meta: {
      footerStyle: "primary"
    }
  },
  {
    path: "/overview",
    name: "Overview",
    component: () => import("../views/Overview/Overview.vue"),
    meta: {
      headerOrder: 0,
      showInHeader: true,
      footerStyle: "secondary"
    }
  },
  {
    path: "/machine/:id",
    name: "Detail",
    component: () => import("../views/Detail/Detail.vue"),
    meta: {
      showInHeader: false,
      footerStyle: "secondary"
    }
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("../views/Faq/Faq.vue"),
    meta: {
      footerStyle: "primary"
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () => import("../views/TermsAndConditions/TermsAndConditions.vue"),
    meta: {
      footerStyle: "secondary"
    }
  },
  {
    path: "/premium",
    name: "Premium",
    component: () => import("../views/Premium/Premium.vue"),
    meta: {
      footerStyle: "primary"
    }
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/Dashboard/Checkout/Checkout.vue"),
    meta: {
      footerStyle: "primary",
      title: "Checkout"
    }
  },
  {
    path: "/machines/create",
    name: "Machine-create",
    component: () => import("../views/Dashboard/MachineForm/MachineForm.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      footerStyle: "primary"
    }
  },
  {
    path: "/machines/edit/:id",
    name: "Machine-edit",
    component: () => import("../views/Dashboard/MachineForm/MachineForm.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      footerStyle: "primary"
    }
  },
  // --- Pre login Account actions ---
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Account/Login/Login.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Account/Register/Register.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/Account/ForgotPassword/ForgotPassword.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/Account/ResetPassword/ResetPassword.vue")
  },
  // --- POST login Account section ---
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/Dashboard/Dashboard.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      footerStyle: "primary"
    },
    children: [
      {
        // alias: "", // TODO: Alias terug op dit zetten als favorieten geïntegreerd is
        path: "favorites",
        name: "Favorites",
        component: () => import("../views/Dashboard/Favorites/Favorites.vue"),
        meta: {
          accountNavigation: false,
          title: "Favorieten"
        }
      },
      {
        path: "vending-machines",
        name: "MyMachines",
        component: () => import("../views/Dashboard/MyMachines/MyMachines.vue"),
        meta: {
          accountNavigation: false,
          title: "Mijn automaten"
        }
      },
      {
        path: "invoices",
        name: "Invoices",
        component: () => import("../views/Dashboard/Invoices/Invoices.vue"),
        meta: {
          accountNavigation: false,
          title: "Facturen"
        }
      },
      {
        alias: "", // TODO: Alias hier weg halen als favorieten geïntegreerd is
        path: "change-password",
        name: "ChangePassword",
        component: () => import("../views/Dashboard/ChangePassword/ChangePassword.vue"),
        meta: {
          accountNavigation: true,
          title: "Wachtwoord wijzigen"
        }
      },
      {
        path: "logout",
        name: "Logout",
        beforeEnter: logOut,
        meta: {
          accountNavigation: true,
          title: "Logout"
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
