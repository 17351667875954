
import { mapMutations, mapGetters, mapActions } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";

const ADDRESS_COMPONENTS = {
  subpremise : 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name'
};

export default {
  name: 'GoogleSearchLocation',
  components: {
    VueGoogleAutocomplete,
  },
  data: () => ({
    currentPositionisActive: false
  }),
  computed: {
    ...mapGetters("machine", {
      currentLocation: "getCurrentLocation",
    }),
  },
  methods: {
    ...mapMutations("machine", {
      setSearchLocation: "SET_SEARCH_LOCATION",
      setMachineFilter: "SET_MACHINE_FILTERS",
    }),
    updateSelectedCategory(e){
      this.setMachineFilter(this.categories.filter(c => c.category_id === e))
    },
    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          pos => {
            this.location = {longitude: pos.coords.latitude, latitude: pos.coords.longitude};
            // this.currentAddress = `${pos.coords.latitude}, ${pos.coords.longitude}`
            var myLatlng = new google.maps.LatLng(
              parseFloat(pos.coords.latitude),
              parseFloat(pos.coords.longitude)
            );
            this.currentPositionisActive = true;
            this.updateCoordinates(myLatlng);
          },
          err => {
            this.currentPositionisActive = false;
            this.errorStr = err.message;
          }
        );
      }

      // Normaal werkt gewoon dit: maar er zit een bug in, dus we hebben methodes uit de plugin gekopierd en herwerkt.
      // this.$refs.searchInput.geolocate();
    },
    focusSearchInput() {
      this.$refs.searchInput.focus();
    },
    getAddressData(addressData, placeResultData, id) {
      this.$emit("google-place-changed", addressData);
      let location = {};
      location.latitude = addressData.longitude;
      location.longitude = addressData.latitude;
      location.formattedAddress = `${addressData.route}, ${addressData.postal_code} ${addressData.administrative_area_level_2}, ${addressData.country}`;
      this.setSearchLocation(location);
      return addressData;
    },
    updateCoordinates(value) {
      if (!value && !(value.lat || value.lng)) return;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: value }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.$emit("placechanged", this.formatResult(results[0]), results[0], this.id);
            this.$refs.searchInput.update(results[0].formatted_address);
            this.location.formattedAddress = results[0].formatted_address;
            this.setSearchLocation(this.location);
          } else {
            this.$emit("error", "no result for provided coordinates");
          }
        } else {
          this.$emit("error", "error getting address from coords");
        }
      });
    },
    formatResult(place) {
      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (ADDRESS_COMPONENTS[addressType]) {
          let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }
      returnData["latitude"] = place.geometry.location.lat();
      returnData["longitude"] = place.geometry.location.lng();
      return returnData;
    }
  },
  created(){
  }
};
