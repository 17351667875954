<template>
  <div
    data-aos="fade-up"
    data-aos-duration="800"
    data-aos-delay="200"
    data-aos-once="true"
    class="o-row"
  >
    <div class="o-container">
      <div class="c-cta">
        <div class="grid">
          <div
            class="row row-middle"
            :class="{ 'row-reverse': rowReverse }"
          >
            <div class="col-md-6">
              <h2>{{ title }}</h2>
              <p class="c-cta__description">
                {{ description }}
              </p>
              <router-link
                :to="{ name: 'Overview' }"
                title="Ga naar Automaten"
              >
                <button class="c-button">
                  {{ buttonText }}
                </button>
              </router-link>
            </div>
            <div class="col-md-6">
              <img
                class="c-cta__image"
                :src="imgPath"
                alt="home"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Cta.controller.js"></script>
<style lang="scss" src="./Cta.styles.scss"></style>