<template>
	<div class="c-share">
		<span class="c-share__title">Share this</span>
		<div class="c-share__links">
			<a @click="shareFacebook()">
				<img src="~@/assets/images/icons/share-facebook.svg" alt="Share on facebook" />
			</a>
			<a @click="shareWhatsapp()">
				<img src="~@/assets/images/icons/share-whatsapp.svg" alt="Share on whatsapp" />
			</a>
			<a @click="shareTwitter()">
				<img src="~@/assets/images/icons/share-twitter.svg" alt="Share on twitter" />
			</a>
		</div>
	</div>
</template>

<script src="./Share.controller.js"></script>
<style lang="scss" src="./Share.styles.scss"></style>
