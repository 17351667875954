<template>
  <!-- <div
    v-click-outside="clickOutside"
    class="filter"
  > -->
  <div
    class="c-search__input"
  >
    <!-- <input
      type="text"
      :placeholder="field.variable"
      :value="value"
    > -->
    <GoogleSearchLocation ref="googleSearchLocation" />
    <img
      :src="require(`@/assets/images/icons/${field.icon}.svg`)"
      :alt="field.variable"
      @click="getCurrentLocation()"
    >
  </div>
</template>

<script src="./FilterSearch.controller.js"></script>
<style scoped lang="scss" src="./FilterSearch.styles.scss"></style>
