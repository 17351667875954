<template>
  <div class="c-input">
    <label
      v-if="label"
      class="c-label"
      :for="label"
    >{{ label }}</label>
    <input
      v-bind="$attrs"
      :id="label"
      v-model="modelValue"
      :type="inputType"
      :autocomplete="autocomplete"
      @input="$emit('update:modelValue', $event.target.value)"
    >
  </div>
</template>

<script src="./InputComponent.controller.js"></script>
<style lang="scss" src="./InputComponent.styles.scss"></style>
