import { api } from "@/utils";
import _ from "lodash";
import MACHINE_MUTATION_TYPES from "./machine.mutation-types";
import { useToast } from "vue-toastification";

const toast = useToast();

const MACHINE_ACTIONS = {
	[MACHINE_MUTATION_TYPES.FETCH_DATA_MACHINES]({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE, true);
			let queryString = "vending-machines?";

			// Pagination
			queryString = queryString.concat(`page_size=99&&page_number=1&`);

			// FILTERS
			if (params) {
				// CATEGORIES
				if (params.categories.length > 0) {
					queryString = queryString.concat(
						`filters=main_category_id[in]${params.categories.join()}|category.category_id[in]${params.categories.join()}&`
					);
				}

				// PAYMENT METHODS
				if (params.paymentMethods.length > 0) {
					queryString = queryString.concat(
						`filters=payment_method.payment_method_id[in]${params.paymentMethods.join()}&`
					);
				}

				// LOCATIONS (indoor / outdoor)
				if (params.locations.length > 0) {
					queryString = queryString.concat(`filters=location.location_id[in]${params.locations.join()}&`);
				}

				// OPENING TIMES ( day / night / 24/7)
				if (params.openingTimes.length > 0) {
					queryString = queryString.concat(
						`filters=opening_time.opening_time_id[in]${params.openingTimes.join()}&`
					);
				}

				// COORDINATES
				if (params.location.latitude !== null && params.location.longitude !== null) {
					queryString = queryString.concat(
						`longitude=${params.location.latitude}&latitude=${params.location.longitude}&sort_by=distance=asc&`
					);
				}
			}

			api.get(queryString)
				.then((response) => {
					commit(MACHINE_MUTATION_TYPES.CLEAR_MACHINES, response.data.result);
					commit(MACHINE_MUTATION_TYPES.APPEND_MACHINES, response.data.result);
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE, false);
					resolve();
				})
				.catch((error) => {
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					reject(error);
				});
		});
	},
	[MACHINE_MUTATION_TYPES.FETCH_DATA_MACHINE]({ commit }, id) {
		return new Promise((resolve, reject) => {
			commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE, true);

			api.get("vending-machines/" + id)
				.then((response) => {
					commit(MACHINE_MUTATION_TYPES.SET_MACHINE, response.data);
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE, false);
					resolve();
				})
				.catch((error) => {
					console.log(error);
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					reject(error);
				});
		});
	},
	[MACHINE_MUTATION_TYPES.FETCH_CATEGORIES]({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(MACHINE_MUTATION_TYPES.CLEAR_MACHINE_FILTER, "Categories");
			commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_CATEGORIES, true);

			api.get("categories?page_size=100&page_number=1")
				.then((response) => {
					commit(MACHINE_MUTATION_TYPES.SET_CATEGORIES, response.data.result);
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_CATEGORIES, false);
					resolve();
				})
				.catch((error) => {
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					reject(error);
				});
		});
	},
	[MACHINE_MUTATION_TYPES.FETCH_PAYMENT_METHODS]({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(MACHINE_MUTATION_TYPES.CLEAR_MACHINE_FILTER, "PaymentMethods");
			commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_PAYMENT_METHODS, true);

			api.get("payment-methods?page_size=100&page_number=1")
				.then((response) => {
					commit(MACHINE_MUTATION_TYPES.SET_PAYMENT_METHODS, response.data.result);
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_PAYMENT_METHODS, false);
					resolve();
				})
				.catch((error) => {
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					reject(error);
				});
		});
	},
	[MACHINE_MUTATION_TYPES.FETCH_LOCATIONS]({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(MACHINE_MUTATION_TYPES.CLEAR_MACHINE_FILTER, "Locations");
			commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_LOCATIONS, true);

			api.get("locations?page_size=100&page_number=1")
				.then((response) => {
					commit(MACHINE_MUTATION_TYPES.SET_LOCATIONS, response.data.result);
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_LOCATIONS, false);
					resolve();
				})
				.catch((error) => {
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					reject(error);
				});
		});
	},
	[MACHINE_MUTATION_TYPES.FETCH_OPENING_TIMES]({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(MACHINE_MUTATION_TYPES.CLEAR_MACHINE_FILTER, "OpeningTimes");
			commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_OPENING_TIMES, true);

			api.get("opening-times?page_size=100&page_number=1")
				.then((response) => {
					commit(MACHINE_MUTATION_TYPES.SET_OPENING_TIMES, response.data.result);
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_OPENING_TIMES, false);
					resolve();
				})
				.catch((error) => {
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					reject(error);
				});
		});
	},
	[MACHINE_MUTATION_TYPES.FETCH_FAQ]({ commit }, params) {
		return new Promise((resolve, reject) => {
			// commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE, true);
			api.get("faq")
				.then((response) => {
					commit(MACHINE_MUTATION_TYPES.SET_FAQ, response.data.result);
					resolve();
				})
				.catch((error) => {
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					reject(error);
				});
		});
	},
	[MACHINE_MUTATION_TYPES.SET_OUT_OF_USE]({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE, true);
			const machine = { out_of_use: params.value };
			api.put(`vending-machines/${params.id}`, machine)
				.then((response) => {
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE, false);
					commit(MACHINE_MUTATION_TYPES.SET_MACHINE, response.data);
					resolve();
				})
				.catch((error) => {
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE, false);
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					reject(error);
				});
		});
	},
	[MACHINE_MUTATION_TYPES.CREATE_MACHINE]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_MACHINE_FORM, true);
			api.post("vending-machines", payload)
				.then((response) => {
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_MACHINE_FORM, false);
					commit(MACHINE_MUTATION_TYPES.SET_MACHINE, response.data);
					resolve(response.data);
				})
				.catch((error) => {
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_MACHINE_FORM, false);
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					console.log(error.response);
					console.log(error.response.data);
					reject(error);
				});
		});
	},
	[MACHINE_MUTATION_TYPES.UPDATE_MACHINE]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_MACHINE_FORM, true);
			api.put(`vending-machines/${payload.vending_machine_id}`, payload)
				.then((response) => {
					toast.success("Machine successfully updated"); // TODO: ADD TRANSLATION KEY

					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_MACHINE_FORM, false);
					commit(MACHINE_MUTATION_TYPES.SET_MACHINE, response.data);
					resolve(response.data);
				})
				.catch((error) => {
					commit(MACHINE_MUTATION_TYPES.SET_LOADING_STATE_MACHINE_FORM, false);
					commit(MACHINE_MUTATION_TYPES.SET_ERROR, error);
					console.log(error.response);
					console.log(error.response.data);
					reject(error);
				});
		});
	},
};

export default MACHINE_ACTIONS;
