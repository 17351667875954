import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { mapGetters } from "vuex";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default {
  name: "TabImages",
  components: {
    Swiper,
    SwiperSlide,
    Navigation
  },
  props: {},
  data: () => ({
    swiper: null,
    // images: [
    //   "https://placem.at/places?w=800&h=800&random=1",
    //   "https://placem.at/places?w=800&h=600&random=1",
    //   "https://placem.at/places?w=1200&h=400&random=2",
    //   "https://placem.at/places?w=800&h=800&random=3",
    //   "https://placem.at/places?w=600&h=800&random=4",
    //   "https://placem.at/places?w=1200&h=400&random=5",
    //   "https://placem.at/places?w=800&h=800&random=6"
    // ],
    imageShown: "",
    hideSlideControls: false,
  }),
  computed: {
    showSlideControls() {
      if (this.images.length > 2) {
        return true;
      }

      return false;
    },
    ...mapGetters("machine", {
      machine: "getMachine",
    }),
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.swiper.update();
    },
    initSwiper() {
      setTimeout(() => {
        this.swiper.update();
      }, 20);
    },
    onSlideChange() {
      console.log("slide change");
    },
    openImage(image){
      this.imageShown = image;
    },
    closeImage(){
      this.imageShown = ""
    }
  },
  created() {}
};
