<template>
  <l-map
    v-model="zoom"
    v-model:zoom="zoom"
    :center="[machine.coordinates.latitude, machine.coordinates.longitude]"
  >
    <l-tile-layer :url="url" />
    <l-marker
      :lat-lng="[machine.coordinates.latitude, machine.coordinates.longitude]"
    >
      <l-icon
        :icon-url="mapIcon(machine)"
        :icon-size="iconSize(machine)"
      />
    </l-marker>
  </l-map>
</template>

<script src="./DetailMap.controller.js"></script>
<style lang="scss" src="./DetailMap.styles.scss"></style>
