export default {
  name: 'Hamburger',
  components: {},
  props: {
    showFlyout: false,
  },
  data: () => ({
    showClose: false,
  }),
  computed: {},
  methods: {
    hamburgerClicked() {
      this.$emit('hamburgerClicked');
    },
  },
};
