export default {
	inheritAttrs: false,
	name: "InputComponent",
	props: {
		modelValue: String,
		value: String,
		label: String,
		field: String,
		item: Array,
	},
	data: () => ({
		example: true,
	}),
};
