<template>
  <div class="c-breadcrumbs">
    <router-link :to="{ name: 'Overview' }">
      zoekresultaten
    </router-link>
    
    <span>{{ name }}</span>
  </div>
</template>

<script src="./Breadcrumbs.controller.js"></script>
<style lang="scss" src="./Breadcrumbs.styles.scss"></style>
