import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("account", {
      token: "getToken",
      currentUser: "getCurrentUser"
    }),
    $currentUser() {
      return this.currentUser;
    },
    $isLoggedIn() {
      return !!(this.token && this.token !== "" && this.token !== null);
    },
    $isVendor() {
      const user = this.currentUser;
      if (user) {
        return !!(user.roles.includes("Admin") || user.roles.includes("SuperAdmin"));
      }
      return false;
    }
  },
  methods: {}
};
