<template>
  <table class="c-openingtimes">
    <tr
      v-for="day in openingTimes"
      :key="day"
    >
      <td>{{ day.name }}</td>
      <td>
        <Chip />
      </td>
      <td>
        <button class="c-button c-button--flat">
          +toevoegen
        </button>
      </td>
      <td>Gesloten</td>
    </tr>
  </table>
</template>

<script src="./OpeningTimes.controller.js"></script>
<style lang="scss" src="./OpeningTimes.styles.scss"></style>
