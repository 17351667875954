import moment from "moment";
import { formatDate } from "@/utils";
import { Tag } from "@/components";

export default {
  name: "MachineTable",
  components: {
    Tag
  },
  props: {
    headers: Array,
    data: Array
  },
  data: () => ({}),
  computed:{
    
  },
  methods: {
    formatDate,
    formatAddress(address){
      return `${address.street} ${address.number}, ${address.zip_code} ${address.city}`
    }
  }
};
