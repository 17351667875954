<template>
	<l-map
		v-model="zoom"
		v-model:zoom="zoom"
		:center="center"
		@click="newMarker"
		@update:zoom="zoomUpdated"
		@update:center="centerUpdated"
	>
		<l-tile-layer :url="url" />
		<l-marker v-for="mark in markers" :key="mark.id" :lat-lng="mark.latLong" :draggable="false" :icon="icon" />
	</l-map>
	<div class="pinpoint-search">
		<GoogleSearchLocation ref="googleSearchLocation" @googlePlaceChanged="addressInputChanged" />
	</div>
</template>

<script src="./PinPointMap.controller.js"></script>
<style lang="scss" src="./PinPointMap.styles.scss"></style>
