export default {
  name: 'PremiumCalculator',
  data() {
    return {
      priceTable: [
        {
          units: 1,
          price: 19
        },
        {
          units: 2,
          price: 19
        },
        {
          units: 3,
          price: 17
        },
        {
          units: 4,
          price: 17
        },
        {
          units: 5,
          price: 17
        },
        {
          units: 6,
          price: 15
        }
      ]
    };
  },
  methods: {
  },
  created() {
    // set default value from api data:
    const defaultPrice = this.priceTable.find(pt => pt.units === 6)?.price;
    if (defaultPrice) {
      this.price = defaultPrice;
    }
  }
};