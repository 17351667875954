import moment from "moment";

const formatDate = date => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }
};

const formatPrice = price => {
  if (price) {
    // TODO: convert the dot to a comma in a more foolproof way
    return `€ ${price.toFixed(2).replace(".", ",")}`;
  }
};

export { formatDate, formatPrice };
