<template>
	<div class="o-row o-row--lg o-row--dark">
		<div class="o-container">
			<div class="c-footer-primary">
				<div class="grid">
					<div class="row row-middle">
						<div class="col-md-4">
							<div class="c-footer-primary__left">
								<div class="c-footer-primary__logo">
									<img src="~@/assets/images/logo-white.svg" alt="vending mapp" />
								</div>
								<p class="c-footer-primary__description">
									Broden, aardbeien, verse voeding, frisdrank... en nog veel meer. Broden, aardbeien,
									verse voeding.
								</p>
							</div>
						</div>
						<div class="col-md-8">
							<div class="c-footer-primary__top">
								<div class="c-footer-primary__nav">
									<ul>
										<li>
											<router-link :to="{ name: 'Faq' }" title="Faq">
												Veelgestelde vragen
											</router-link>
										</li>
										<li>
											<router-link :to="{ name: 'Overview' }" title="Ga naar Automaten">
												Automaten
											</router-link>
										</li>
										<li>
											<router-link
												:to="{ name: 'TermsAndConditions' }"
												title="Algemene voorwaarden"
											>
												Algemene voorwaarden
											</router-link>
										</li>
										<li><a href="mailto:info@vendingmapp.com">Contact</a></li>
									</ul>
								</div>
								<a
									class="c-button c-button--white c-button--small-radius"
									href="mailto:info@vendingmapp.com"
									>Contacteer ons
								</a>
							</div>
							<hr />
							<div class="c-footer-primary__bottom">
								<div class="c-footer-primary__language">
									<!-- TODO: Unhide when integrating multi language -->
									<span v-if="false">Taal wijzigen:</span>
									<Language v-if="false" />
									<p>
										Designed and developed by
										<a class="c-footer-primary__link" href="https://modavi.be">Modavi</a>.
									</p>
								</div>
								<div class="c-footer-primary__socials">
									<a href="https://www.facebook.com/vendingmapp" target="_blank"
										><img src="~@/assets/images/icons/facebook.svg" alt=""
									/></a>

									<a href="https://www.instagram.com/vendingmapp" target="_blank"
										><img src="~@/assets/images/icons/instagram.svg" alt=""
									/></a>

									<a href="https://twitter.com/vendingmapp" target="_blank"
										><img src="~@/assets/images/icons/twitter.svg" alt=""
									/></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script src="./FooterPrimary.controller.js"></script>
<style lang="scss" src="./FooterPrimary.styles.scss"></style>
