<template>
  <div class="o-row">
    <div class="o-container o-container--sm">
      <form class="c-searchbar">
        <div class="grid">
          <div class="row">
            <div class="col-md-7">
              <div
                class="c-searchbar__field"
              >
                <div>
                  <label for="location">Locatie</label>
                  <GoogleSearchLocation ref="googleSearchLocation" />
                </div>
                <button @click.prevent="getCurrentLocation()">
                  <img
                    v-if="!currentPositionisActive"
                    src="@/assets/images/searchbar-location.svg"
                    alt="current location"
                  >
                  <img
                    v-else
                    src="@/assets/images/searchbar-location-filled.svg"
                    alt="current location"
                  >
                </button>
              </div>
            </div>
            <div class="col-md-5">
              <div class="c-searchbar__field c-searchbar__field--dropdown">
                <div class="c-input">
                  <label for="location">Categorie</label>
                  <!-- <input
                    placeholder="Brood"
                    type="text"
                  > -->
                  <Multiselect
                    v-if="categories.length > 0"
                    v-model="selectedCategory"
                    class="c-searchbar__field c-searchbar__field--multiselect"
                    track-by="category_id"
                    label="name"
                    value-prop="category_id"
                    placeholder="Kies een categorie"
                    :options="categories"
                    :show-labels="false"
                    :allow-empty="false"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    @change="updateSelectedCategory"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link
          class="c-button"
          :to="{ name: 'Overview' }"
          title="Ga naar Automaten"
        >
          <img
            src="@/assets/images/searchbar-icon.svg"
            alt="search"
          >
        </router-link>
      </form>
      <router-link
        class="c-searchbar__map-shortcut"
        :to="{ name: 'Overview' }"
        title="Ga naar Automaten"
      >
        Bekijk de kaart
        <img
          src="@/assets/images/icons/arrow-right.svg"
          alt=""
        >
      </router-link>
    </div>
  </div>
</template>
<script src="./Searchbar.controller.js"></script>
<style lang="scss" src="./Searchbar.styles.scss"></style>
