export default {
  name: "Share",
  props: {

  },
  components: {},
  data: () => ({}),
  methods:{
    shareFacebook(){
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, "_blank");
    },
    shareWhatsapp() {
      window.open(`https://api.whatsapp.com/send?text=${window.location.href}`, "_blank");
    },
    shareTwitter(){
      window.open(`https://twitter.com/intent/tweet?text=${window.location.href}`, "_blank");
    },
  },
  computed: {

  }
};
