// import { createStore } from 'vuex';

// export default createStore({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   },
// });

import { accountStore } from './account';
import { machineStore } from './machine';

/* ==========================================================================
   STORE MODULES
   ========================================================================== */
export const STORE_MODULES = {
  account: accountStore,
  machine: machineStore
};
