<template>
  <div class="c-language">
    <!-- https://jsfiddle.net/q6Lnpr7a/ -->
    <!-- https://github.com/vueform/multiselect -->
    <Multiselect
      v-model="currentLanguage"
      label="abbreviation"
      :can-deselect="false"
      :options="languages"
    >
      <!-- SELECTED WEERGAVE -->
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label">
          {{ value.abbreviation }}
        </div>
      </template>

      <!-- OPEN DROPDOWN ITEM WEERGAVE -->
      <template #option="{ option }">
        <span :class="'flag-icon flag-icon-' + option.isoCode" />
        <span class="c-language__abbreviation">{{ option.abbreviation }}</span>
      </template>
    </Multiselect>
  </div>
</template>

<script src="./Language.controller.js"></script>
<style lang="scss" src="./Language.styles.scss"></style>
