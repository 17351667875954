import { FooterPrimary, FooterSecondary } from "@/components";

export default {
  name: "Footer",
  components: {
    FooterPrimary,
    FooterSecondary
  },
  computed: {
    primaryFooter() {
      if (this.$route.meta.footerStyle && this.$route.meta.footerStyle == "primary") {
        return true;
      }
      return false;
    },
    secondaryFooter() {
      if (this.$route.meta.footerStyle && this.$route.meta.footerStyle == "secondary") {
        return true;
      }
      return false;
    }
  }
};
